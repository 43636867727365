<template>
  <div>
    <b-tabs>
      <b-tab>
        <template #title>
          <i class="fa fa-percent mr-1"></i>Desconto(s)
        </template>
        <b-card-text class="p-3">
          <div class="row">
            <div class="col-12 mt-3">
              <b-checkbox switch v-model="objEdit.premios.desconto.ativo"
                >Ativo</b-checkbox
              >
            </div>
            <div class="col-6 mt-3">
              <label>Validade (dias)</label>
              <b-input
                v-model="objEdit.premios.desconto.validadeDias"
              ></b-input>
            </div>
            <div class="col-6 mt-3">
              <label>Quantidade Disponível</label>
              <b-input
                v-model="objEdit.premios.desconto.qtdDisponivel"
              ></b-input>
            </div>
            <div class="col-6 mt-3">
              <label>Valor do Desconto em %</label>
              <b-input v-model="objEdit.premios.desconto.valor"></b-input>
            </div>
            <div class="col-6 mt-3">
              <label>Valor mínimo do pedido</label>
              <money
                class="form-control"
                v-model="objEdit.premios.desconto.compraMinima"
              ></money>
            </div>
          </div>
        </b-card-text>
      </b-tab>

      <b-tab>
        <template #title>
          <i class="fa fa-pizza-slice mr-1"></i>Produto(s)
        </template>
        <b-card-text class="p-3">
          <div class="row">
            <div class="col-6 mt-3">
              <b-checkbox switch v-model="objEdit.premios.produto.ativo"
                >Ativo</b-checkbox
              >
            </div>
            <div class="col-6 mt-3">
              <b-checkbox switch v-model="objEdit.premios.produto.multiplos"
                >Escolhe multiplos produtos?</b-checkbox
              >
            </div>
            <div class="col-6 mt-3">
              <label>Validade (dias)</label>
              <b-input v-model="objEdit.premios.produto.validadeDias"></b-input>
            </div>
            <div class="col-6 mt-3">
              <label>Quantidade Disponível</label>
              <b-input
                v-model="objEdit.premios.produto.qtdDisponivel"
              ></b-input>
            </div>
            <div class="col-12 mt-3">
              <label>Produto(s) Elegíveis</label>
              <v-select
                multiple
                v-model="objEdit.premios.produto.codigos"
              ></v-select>
            </div>
          </div>
        </b-card-text>
      </b-tab>

      <b-tab>
        <template #title>
          <i class="fa fa-layer-group mr-1"></i>Combo(s)
        </template>
        <b-card-text class="p-3">
          <b-checkbox switch v-model="objEdit.premios.combo.ativo"
            >Ativo</b-checkbox
          >
          <div class="row">
            <div class="col-6">
              <label>Validade (dias)</label>
              <b-input v-model="objEdit.premios.combo.validadeDias"></b-input>
            </div>
            <div class="col-6">
              <label>Quantidade Disponível</label>
              <b-input v-model="objEdit.premios.combo.qtdDisponivel"></b-input>
            </div>
          </div>
        </b-card-text>
      </b-tab>

      <b-tab>
        <template #title>
          <i class="fa fa-motorcycle mr-1"></i>Entrega Gratis
        </template>
        <b-card-text class="p-3">
          <b-checkbox switch v-model="objEdit.premios.entrega.ativo"
            >Ativo</b-checkbox
          >
          <div class="row">
            <div class="col-6">
              <label>Validade (dias)</label>
              <b-input v-model="objEdit.premios.entrega.validadeDias"></b-input>
            </div>
            <div class="col-6">
              <label>Quantidade Disponível</label>
              <b-input
                v-model="objEdit.premios.entrega.qtdDisponivel"
              ></b-input>
            </div>
          </div>
        </b-card-text>
      </b-tab>

      <b-tab>
        <template #title>
          <i class="fa fa-motorcycle mr-1"></i>Ofertas Exclusivas
        </template>
        <b-card-text class="p-3">
          <b-checkbox switch v-model="objEdit.premios.ofertas.ativo"
            >Ativo</b-checkbox
          >
          <div class="row">
            <div class="col-6">
              <label>Validade (dias)</label>
              <b-input v-model="objEdit.premios.ofertas.validadeDias"></b-input>
            </div>
            <div class="col-6">
              <label>Quantidade Disponível</label>
              <b-input
                v-model="objEdit.premios.ofertas.qtdDisponivel"
              ></b-input>
            </div>
          </div>
        </b-card-text>
      </b-tab>
    </b-tabs>
  </div>
</template>

<script>
export default {
  components: {},
  props: {
    objEdit: Object,
  },
  mounted() {},
  data() {
    return {};
  },
  watch: {},
  computed: {},
  methods: {},
};
</script>

<style lang="scss" scoped></style>
