var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"d-flex justify-content-between"},[_c('b-checkbox',{attrs:{"switch":""},model:{value:(_vm.whatsapp.ativo),callback:function ($$v) {_vm.$set(_vm.whatsapp, "ativo", $$v)},expression:"whatsapp.ativo"}},[_vm._v("Whatsapp Ativado")]),_c('div')],1),(_vm.whatsapp.ativo)?_c('div',[_c('b-form-group',{attrs:{"label":"Token"}},[_c('b-input',{model:{value:(_vm.whatsapp.token),callback:function ($$v) {_vm.$set(_vm.whatsapp, "token", $$v)},expression:"whatsapp.token"}})],1),_c('hr'),_c('div',{staticClass:"d-flex justify-content-between py-4 my-4"},[_c('div',{staticClass:"text-center",class:{
          'text-danger w-50':
            _vm.whatsapp.status && _vm.whatsapp.status == 'Desconectado',
          'text-success w-100':
            _vm.whatsapp.status && _vm.whatsapp.status == 'Conectado',
        }},[_c('h2',[(_vm.whatsapp.status && _vm.whatsapp.status == 'Conectado')?_c('b-icon-check-circle-fill'):_vm._e(),(_vm.whatsapp.status && _vm.whatsapp.status == 'Desconectado')?_c('b-icon-x-circle-fill'):_vm._e(),_vm._v(" "+_vm._s(_vm.whatsapp.status)+" ")],1),(_vm.whatsapp.status && _vm.whatsapp.status == 'Conectado')?_c('b-card',{staticClass:"mt-6 my-4 pt -2 text-caption text-secondary m-auto",staticStyle:{"max-width":"400px"}},[_c('h6',{staticClass:"d-flex mt-2"},[_c('b-input',{directives:[{name:"mask",rawName:"v-mask",value:(['(##) ####-####', '(##) #####-####']),expression:"['(##) ####-####', '(##) #####-####']"}],model:{value:(_vm.testPhone),callback:function ($$v) {_vm.testPhone=$$v},expression:"testPhone"}}),_c('b-btn',{on:{"click":_vm.testWpp}},[_vm._v("Testar")])],1)]):_vm._e()],1),(
          _vm.info &&
          _vm.whatsapp.status == 'Desconectado' &&
          _vm.info.lastQrCode &&
          _vm.info.lastQrCode.qrCodeUrl
        )?_c('div',{staticClass:"text-center w-50"},[_c('b-img',{attrs:{"src":_vm.info.lastQrCode.qrCodeBase64,"fluid":""}})],1):_vm._e()]),_c('hr'),_c('b-card',[_c('b',[_vm._v("Fiscal")]),_c('b-checkbox',{attrs:{"switch":""},model:{value:(_vm.whatsapp.fiscal.ativo),callback:function ($$v) {_vm.$set(_vm.whatsapp.fiscal, "ativo", $$v)},expression:"whatsapp.fiscal.ativo"}},[_vm._v("Enviar Notas Fiscais")]),_c('b-checkbox',{attrs:{"switch":""},model:{value:(_vm.whatsapp.fiscal.enviarAutomatico),callback:function ($$v) {_vm.$set(_vm.whatsapp.fiscal, "enviarAutomatico", $$v)},expression:"whatsapp.fiscal.enviarAutomatico"}},[_vm._v("Enviar Automaticamente ao Emitir")])],1)],1):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }