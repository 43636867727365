<template>
  <div>
    <div class="d-flex justify-content-between">
      <b-checkbox
        switch
        v-model="whatsapp.ativo"
        >Whatsapp Ativado</b-checkbox
      >
      <div></div>
    </div>
    <div v-if="whatsapp.ativo">
      <b-form-group label="Token">
        <b-input v-model="whatsapp.token" />
      </b-form-group>

      <hr />

      <div class="d-flex justify-content-between py-4 my-4">
        <div
          class="text-center"
          :class="{
            'text-danger w-50':
              whatsapp.status && whatsapp.status == 'Desconectado',
            'text-success w-100':
              whatsapp.status && whatsapp.status == 'Conectado',
          }"
        >
          <h2>
            <b-icon-check-circle-fill
              v-if="whatsapp.status && whatsapp.status == 'Conectado'"
            ></b-icon-check-circle-fill>
            <b-icon-x-circle-fill
              v-if="whatsapp.status && whatsapp.status == 'Desconectado'"
            ></b-icon-x-circle-fill>
            {{ whatsapp.status }}
          </h2>
          <b-card
            v-if="whatsapp.status && whatsapp.status == 'Conectado'"
            class="mt-6 my-4 pt -2 text-caption text-secondary m-auto"
            style="max-width: 400px"
          >
            <h6 class="d-flex mt-2">
              <b-input
                v-model="testPhone"
                v-mask="['(##) ####-####', '(##) #####-####']"
              ></b-input>
              <b-btn @click="testWpp">Testar</b-btn>
            </h6>
          </b-card>
        </div>

        <div
          v-if="
            info &&
            whatsapp.status == 'Desconectado' &&
            info.lastQrCode &&
            info.lastQrCode.qrCodeUrl
          "
          class="text-center w-50"
        >
          <b-img
            :src="info.lastQrCode.qrCodeBase64"
            fluid
          />
        </div>
      </div>
      <hr />
      <b-card>
        <b>Fiscal</b>
        <b-checkbox
          switch
          v-model="whatsapp.fiscal.ativo"
          >Enviar Notas Fiscais</b-checkbox
        >
        <b-checkbox
          switch
          v-model="whatsapp.fiscal.enviarAutomatico"
          >Enviar Automaticamente ao Emitir</b-checkbox
        >
      </b-card>
    </div>
  </div>
</template>

<script>
import WhatsAppService from "../../libs/WhatsAppService";

export default {
  components: {},
  props: {
    whatsapp: Object,
  },
  mounted() {
    this.fetchobj();
    this.startTimerStatus();
  },
  beforeDestroy() {
    if (this.tmrStatus) {
      clearInterval(this.tmrStatus);
    }
  },
  beforeRouteLeave(to, from, next) {
    if (this.tmrStatus) {
      clearInterval(this.tmrStatus);
    }
    next();
  },
  data() {
    return {
      obj: null,
      tmrStatus: null,
      info: null,
      testPhone: "",
    };
  },
  watch: {
    whatsapp() {
      this.fetchobj();
    },
    obj: {
      handler: function (val) {
        this.$emit("update:whatsapp", val);
        this.startTimerStatus();
      },
      deep: true,
    },
  },
  computed: {},
  methods: {
    async testWpp() {
      let res = await WhatsAppService.enviarTextoSimples(
        this.testPhone,
        "Teste de conexão"
      );
      if (!res) {
        this.$swal(
          "Erro ao enviar mensagem",
          "Verifique se o número está correto",
          "error"
        );
      } else {
        this.$swal("Mensagem enviada", "Verifique o celular", "success");
      }
      console.log("res", res);
    },
    startTimerStatus() {
      if (this.tmrStatus) {
        clearInterval(this.tmrStatus);
      }
      if (this.obj && this.obj.ativo) {
        this.tmrStatus = setInterval(() => {
          this.getStatus();
        }, 2000);
      }
    },
    fetchobj() {
      this.obj = this.whatsapp;
    },
    async getStatus() {
      WhatsAppService.setToken(this.whatsapp.token);
      let status = await WhatsAppService.getInfo();
      // console.log("status", status);
      this.info = status;
      if (status) {
        this.whatsapp.status =
          status &&
          status.connectionStatus &&
          status.connectionStatus == "connected"
            ? "Conectado"
            : "Desconectado";
      } else {
        this.whatsapp.status = "Desconfigurado";
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
