
const premios = {
  desconto: {
    ativo: false,
    valor: 10,
    compraMinima: 10,
    qtdDisponivel: 0, //qtd que vai ganhar ao atingir a meta
    validadeDias: 100, //tempo de validade para uso
  },
  produto: {
    ativo: false,
    codigos: [],
    multiplos: false,
    compraMinima: 0,
    qtdDisponivel: 0, //qtd que vai ganhar ao atingir a meta
    validadeDias: 100, //tempo de validade para uso
  },
  combo: {
    ativo: false,
    codigos: [],
    multiplos: false,
    compraMinima: 0,
    qtdDisponivel: 0, //qtd que vai ganhar ao atingir a meta
    validadeDias: 100, //tempo de validade para uso
  },
  entrega: {
    ativo: false,
    desconto: 0, //em %
    compraMinima: 0,
    qtdDisponivel: 0, //qtd que vai ganhar ao atingir a meta
    validadeDias: 100, //tempo de validade para uso
  },
  ofertas: {
    ativo: false,
    comboCodigos: [],
    produtoCodigos: [],
    qtdDisponivel: 0, //qtd que vai ganhar ao atingir a meta
    validadeDias: 100, //tempo de validade para uso
  },

}

const fidelizacoes = [
  {
    ativo: false,
    tipo: 'Pontos de fidelidade',
    descricao: 'Nesse programa, os clientes acumulam pontos toda vez que realizam um pedido através do aplicativo. Os pontos podem ser trocados por descontos, brindes ou benefícios exclusivos. Por exemplo, a cada pedido, o cliente ganha 1 ponto e, quando acumula 10 pontos, pode resgatar um desconto de 10% em seu próximo pedido.',
    obj: {
      pontosAoComprar: 0,
      pontosParaTroca: 10,
      premios: Object.assign({}, { ...premios }),
      validadePontosDias: 100,
    }
  },
  {
    ativo: false,
    tipo: 'Níveis de fidelidade/assinatura',
    descricao: 'Nesse modelo, os clientes são classificados em diferentes níveis de acordo com o seu engajamento e frequência de pedidos. Cada nível oferece benefícios e recompensas crescentes. Por exemplo, um cliente pode começar no nível bronze e, à medida que realiza mais pedidos, pode subir para os níveis prata, ouro e platina, ganhando benefícios adicionais em cada nível.',
    obj: {
      tipoAtivacao: 'pedido', //porPedido ou porAssinatura
      validadePontosDias: 100,
      tipoCobranca: 'unico', //unico ou mensal
      niveis: [
        {
          nivel: 'Bronze',
          premios: Object.assign({}, { ...premios }),
          codProdutoParaComprarNivel: 0
        },
        {
          nivel: 'Prata',
          premios: Object.assign({}, { ...premios }),
          codProdutoParaComprarNivel: 0
        },
        {
          nivel: 'Ouro',
          premios: Object.assign({}, { ...premios }),
          codProdutoParaComprarNivel: 0
        },
        {
          nivel: 'Platina',
          premios: Object.assign({}, { ...premios }),
          codProdutoParaComprarNivel: 0
        },
      ]
    }
  },
  {
    ativo: false,
    tipo: 'Recompensas por feedback',
    descricao: 'Nesse programa, os clientes recebem recompensas por fornecer feedback sobre a experiência deles com o aplicativo ou com o serviço de entrega. Isso pode ser feito por meio de pesquisas rápidas, avaliações de produtos ou compartilhamento de opiniões nas redes sociais. As recompensas podem ser em forma de pontos, descontos ou brindes.',
    obj: {
      tipoAtivacao: '', //aoAvaliar, aoIndicar
      premios: Object.assign({}, { ...premios }),
    }
  }
]


export {
  fidelizacoes,
}