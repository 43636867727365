<template>
  <div>
    <b-table
      :items="lista"
      :fields="[
        { key: 'cod_caixa', label: 'Código', class: 'text-center' },
        { key: 'nome', label: 'Caixa' },
        { key: 'salao', label: 'Salão' },
        { key: 'cod_custo', label: 'Centro Custo' },
        { key: 'opc', label: '', class: 'text-center' },
      ]"
      small
      style="font-size: 11px"
      hover
    >
      <template #cell(cod_custo)="row">
        {{ row.item.custoNome }}
      </template>
      <template #cell(salao)="row">
        <b-check
          v-model="row.item.salao"
          :value="1"
          :unchecked-value="0"
          switch
        />
      </template>
      <template #cell(opc)="row">
        <b-btn size="sm" variant="warning" @click="openForm(row.item)">
          <b-icon-pen /> Editar
        </b-btn>
      </template>
    </b-table>

    <b-btn variant="none" @click="openForm"><b-icon-plus /> Adicionar</b-btn>

    <b-modal id="modal-caixa-form" hide-footer title="Adicionar/editar caixa">
      <b-form @submit.prevent="salvar">
        <div class="row">
          <div class="col-12 col-sm-4">
            <b-form-group label="Código">
              <b-input type="number" v-model="obj.cod_caixa" readonly />
            </b-form-group>
          </div>
          <div class="col-12 col-sm-4 text-center pt-3">
            <label>
              <b-check
                switch
                v-model="obj.salao"
                :value="1"
                :unchecked-value="0"
              >
              </b-check>
              <i class="fas fa-utensils"></i>
              Salão / Mesas
            </label>
          </div>
          <div class="col-12 col-sm-4 text-center pt-3">
            <label>
              <b-check
                switch
                v-model="obj.imprimir_resumo_pedido"
                :value="1"
                :unchecked-value="0"
              >
              </b-check>
              <b-icon-printer /> Resumo de Pedido
            </label>
          </div>
          <div class="col-12 mt-2">
            <b-form-group label="Descrição / Caixas">
              <b-input type="text" v-model="obj.nome" />
            </b-form-group>
          </div>
          <div class="col-12 mt-2">
            <b-form-group label="Carteira / Conta Padrão">
              <v-select
                :options="contasDisponiveis"
                v-model="obj.carteira_id"
                label="nome"
                :reduce="
                  (vl) => {
                    return vl.id;
                  }
                "
              />
            </b-form-group>
          </div>
          <div class="col-12 mt-2">
            <select-centro-custo
              :custoId="{cod_custo: obj.cod_custo, nome: obj.custoNome}"
              @change="changeCusto"
              :return-object="true"
              :showTitle="true"
            />
          </div>
        </div>
        <hr />
        <div class="text-right">
          <b-btn variant="primary" type="submit" class="mr-2"
            ><i class="fa fa-save" /> Salvar</b-btn
          >
          <b-btn variant="secondary" type="button" @click="closeForm"
            ><i class="fa fa-ban" /> Cancelar</b-btn
          >
        </div>
      </b-form>
    </b-modal>
  </div>
</template>

<script>
import CarteiraLib from "../../libs/Carteiras";
import SelectCentroCusto from "../Financeiro/SelectCentroCusto.vue";

export default {
  components: { SelectCentroCusto },
  props: {
    lista: Array,
    codEmpresa: Number,
  },
  mounted() {
    this.carregarContas();
  },
  data() {
    return {
      obj: {
        cod_caixa: 0,
        cod_empresa: 0,
        nome: "",
        salao: 0,
        imprimir_resumo_pedido: 0,
        carteira_id: 0,
      },
      contasDisponiveis: [],
    };
  },
  watch: {
    caixas: function () {
      if (this.value && this.value.length > 0) {
        this.lista = this.value;
      }
    },
    lista: {
      deep: true,
      handler() {
        this.$emit("change", this.lista);
      },
    },
  },
  computed: {},
  methods: {
    async carregarContas() {
      this.contasDisponiveis = (await CarteiraLib.getCareteiras()).data;
      console.log("cariteras", this.contasDisponiveis);
    },
    async salvar(ev) {
      if (ev) ev.preventDefault();

      let errs = [];

      if (!this.obj.nome || this.obj.nome.trim() === "") {
        errs.push("Inform um nome / descrição para o caixa!");
      }

      if (errs.length > 0) {
        this.$swal({
          title: "Atenção",
          html: "Verifique os campos abaixo<hr/>" + errs.join("<hr/>"),
        });
      } else {
        if (!this.lista || this.lista.length <= 0) {
          this.lista = [];
        }
        //if (this.lista && this.lista.length > 0) {
        //if(this.lista.find(x=>x.cod_caixa == this.obj.cod_caixa))
        if (this.obj.cod_caixa && this.obj.cod_caixa > 0) {
          let has = this.lista.find((x) => x.cod_caixa == this.obj.cod_caixa);
          if (has) {
            has = Object.assign(has, this.obj);
          }
        } else {
          this.obj.cod_caixa = null;
          this.lista.push(this.obj);
        }
      }

      this.resetObj();
      this.$bvModal.hide("modal-caixa-form");
      //}
    },
    resetObj() {
      this.obj = {
        cod_caixa: 0,
        cod_empresa: 0,
        nome: "",
        salao: 0,
        imprimir_resumo_pedido: 0,
      };
    },
    openForm(obj) {
      if (obj) {
        this.obj = Object.assign({}, { ...obj });
      } else {
        this.resetObj();
      }
      this.$bvModal.show("modal-caixa-form");
    },
    closeForm() {
      this.$bvModal.hide("modal-caixa-form");
    },
    changeCusto(custo) {
      console.log(custo);
      if (custo && custo.cod_custo) {
        this.obj.cod_custo = custo.cod_custo;
        this.obj.custoNome = custo.nome;
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
