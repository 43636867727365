var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"text-right m-2"},[_c('b-btn',{attrs:{"variant":"outline"},on:{"click":_vm.novo}},[_c('b-icon-plus'),_vm._v(" Adicionar")],1)],1),_c('b-table',{attrs:{"items":_vm.lista,"fields":[
      { key: 'Tipo' },
      { key: 'Sistema' },
      { key: 'Ativo', label: 'Ativo', class: 'text-center' },
      { key: 'Padrao', label: 'Padrão', class: 'text-center' },
      { key: 'Opcoes', label: '', class: 'text-center' },
    ]},scopedSlots:_vm._u([{key:"cell(Ativo)",fn:function(row){return [(row.item.Ativo === true)?_c('b-icon-check-circle'):_vm._e()]}},{key:"cell(Padrao)",fn:function(row){return [(row.item.Padrao === true)?_c('b-icon-check-circle'):_vm._e()]}},{key:"cell(Opcoes)",fn:function(row){return [_c('div',{staticClass:"text-center"},[_c('b-btn',{staticClass:"mr-1",attrs:{"variant":"warning"},on:{"click":function($event){return _vm.editarIntegracao(row.item)}}},[_c('i',{staticClass:"fa fa-edit"})]),_c('b-btn',{attrs:{"variant":"danger"},on:{"click":function($event){return _vm.removerIntegracao(row.item)}}},[_c('i',{staticClass:"fa fa-trash"})])],1)]}}])}),_c('b-modal',{attrs:{"id":"modal-add-integracao","hide-footer":"","title":"Adicionar/Editar Integração"}},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 col-sm-6 pb-4"},[_c('b-check',{attrs:{"switch":""},model:{value:(_vm.obj.Padrao),callback:function ($$v) {_vm.$set(_vm.obj, "Padrao", $$v)},expression:"obj.Padrao"}},[_vm._v("Tornar Padrão para o TIPO")])],1),_c('div',{staticClass:"col-12 col-sm-6 pb-4"},[_c('b-check',{attrs:{"switch":""},model:{value:(_vm.obj.Ativo),callback:function ($$v) {_vm.$set(_vm.obj, "Ativo", $$v)},expression:"obj.Ativo"}},[_vm._v("ATIVO?")])],1),_c('div',{staticClass:"col-12 col-sm-6"},[_c('b',[_vm._v("Tipo")]),_c('br'),_c('b-select',{attrs:{"options":['Fiscal', 'Delivery']},model:{value:(_vm.obj.Tipo),callback:function ($$v) {_vm.$set(_vm.obj, "Tipo", $$v)},expression:"obj.Tipo"}})],1),_c('div',{staticClass:"col-12 col-sm-6"},[_c('b',[_vm._v("Sistema")]),_c('br'),_c('b-select',{attrs:{"options":['VHSYS', 'OnPedido', 'IFood']},model:{value:(_vm.obj.Sistema),callback:function ($$v) {_vm.$set(_vm.obj, "Sistema", $$v)},expression:"obj.Sistema"}})],1),_c('div',{staticClass:"col-12 col-sm-6 pt-3"},[_c('b',[_vm._v("Usuario / ClientID / etc")]),_c('br'),_c('b-input',{attrs:{"disabled":_vm.obj.Sistema === 'IFood' &&
              _vm.obj.Login &&
              _vm.obj.Login != '' &&
              _vm.obj.Adicionais &&
              _vm.obj.Adicionais.some((x) => x.chave == 'autorizado') &&
              _vm.obj.Adicionais.find((x) => x.chave == 'autorizado').valor ==
                'true'?true:false},model:{value:(_vm.obj.Login),callback:function ($$v) {_vm.$set(_vm.obj, "Login", $$v)},expression:"obj.Login"}}),(
              _vm.obj.Sistema === 'IFood' &&
              _vm.obj.Login &&
              _vm.obj.Login != '' &&
              _vm.obj.Adicionais &&
              _vm.obj.Adicionais.find((x) => x.chave == 'autorizado') &&
              _vm.obj.Adicionais.find((x) => x.chave == 'autorizado').valor ==
                'true'
            )?_c('div',{staticClass:"text-success"},[_c('b-icon-check-circle'),_vm._v(" Acesso autorizado! "),_c('b-btn',{attrs:{"variant":"danger"},on:{"click":function($event){_vm.obj.Login = ''}}},[_c('b-icon-x'),_vm._v(" Cancelar Acesso")],1)],1):_vm._e(),(
              _vm.obj.Sistema === 'IFood' &&
              (!_vm.obj.Login || _vm.obj.Login == '') ||
              (!_vm.obj.Adicionais ||
                !_vm.obj.Adicionais.find((x) => x.chave == 'autorizado') ||
                _vm.obj.Adicionais.find((x) => x.chave == 'autorizado').valor !=
                  'true')
            )?_c('b-btn',{attrs:{"variant":"danger","disabled":_vm.loadingIfood},on:{"click":_vm.solicitarChaveIfood}},[(_vm.loadingIfood)?_c('b-spinner',{attrs:{"small":""}}):_vm._e(),(!_vm.loadingIfood)?_c('b-icon-key'):_vm._e(),_vm._v(" Solicitar Autorização ")],1):_vm._e(),(
              _vm.obj.Sistema === 'IFood' &&
              _vm.obj.Login &&
              _vm.obj.Login != '' &&
              (!_vm.obj.Adicionais ||
                !_vm.obj.Adicionais.find((x) => x.chave == 'autorizado') ||
                _vm.obj.Adicionais.find((x) => x.chave == 'autorizado').valor !=
                  'true')
            )?_c('b-btn',{attrs:{"variant":"warning","disabled":_vm.loadingIfood},on:{"click":_vm.autorizarChaveIfood}},[(_vm.loadingIfood)?_c('b-spinner',{attrs:{"small":""}}):_vm._e(),(!_vm.loadingIfood)?_c('b-icon-key'):_vm._e(),_vm._v(" Confirmar Autorização ")],1):_vm._e()],1),(_vm.obj.Sistema !== 'IFood')?_c('div',{staticClass:"col-12 col-sm-6 pt-3"},[_c('b',[_vm._v("Senha / ClientSecret")]),_c('br'),_c('b-input',{model:{value:(_vm.obj.Senha),callback:function ($$v) {_vm.$set(_vm.obj, "Senha", $$v)},expression:"obj.Senha"}})],1):_vm._e(),(_vm.obj.Sistema !== 'IFood')?_c('div',{staticClass:"col-12 pt-3"},[_c('b',[_vm._v("Token")]),_c('br'),_c('b-input',{model:{value:(_vm.obj.Token),callback:function ($$v) {_vm.$set(_vm.obj, "Token", $$v)},expression:"obj.Token"}})],1):_vm._e(),_c('div',{staticClass:"col-12 pt-3"},[_c('hr'),_c('b',[_vm._v("Adicionais")]),_c('b-table',{attrs:{"items":_vm.obj.Adicionais,"fields":[
              { key: 'chave', class: 'text-center' },
              { key: 'valor', class: 'text-center' },
              { key: 'opc', label: '' },
            ]},scopedSlots:_vm._u([{key:"cell(valor)",fn:function(row){return [_c('div',{staticStyle:{"word-break":"break-all"}},[_c('b-input',{attrs:{"placeholder":"Valor"},model:{value:(row.item.valor),callback:function ($$v) {_vm.$set(row.item, "valor", $$v)},expression:"row.item.valor"}})],1)]}},{key:"cell(opc)",fn:function(row){return [_c('b-btn',{attrs:{"variant":"danger"},on:{"click":function($event){return _vm.delAdd(row.item)}}},[_c('b-icon-trash')],1)]}}])}),_c('b-card',[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-5"},[_c('b-input',{attrs:{"placeholder":"Chave"},model:{value:(_vm.add.chave),callback:function ($$v) {_vm.$set(_vm.add, "chave", $$v)},expression:"add.chave"}})],1),_c('div',{staticClass:"col-5"},[_c('b-input',{attrs:{"placeholder":"Valor"},model:{value:(_vm.add.valor),callback:function ($$v) {_vm.$set(_vm.add, "valor", $$v)},expression:"add.valor"}})],1),_c('div',{staticClass:"col-2"},[_c('b-btn',{on:{"click":_vm.addAdd}},[_c('b-icon-plus')],1)],1)])])],1)])]),_c('div',{staticClass:"text-right"},[_c('hr'),_c('b-btn',{staticClass:"mr-1",attrs:{"variant":"success"},on:{"click":_vm.salvar}},[_c('i',{staticClass:"fa fa-save"}),_vm._v(" Salvar")]),_c('b-btn',{attrs:{"variant":"secondary"},on:{"click":_vm.cancel}},[_c('i',{staticClass:"fa fa-ban"}),_vm._v(" Cancelar")])],1)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }