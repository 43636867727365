<template>
  <div>
    <b-table
      :items="lista"
      :fields="campos"
      responsive="md"
      :filter="filtro"
      :per-page="paginacao.registros_por_pagina"
      :current-page="paginacao.pagina_atual"
      striped
      :busy="loading"
      selectable
      select-mode="single"
      @row-selected="onRowSelected"
      ref="empresasTable"
      small
      style="font-size: 11px"
      stacked="sm"
    >
      <template #table-busy class="text-center">
        <b-spinner />Carregando...
      </template>

      <template #cell(actions)="row">
        <b-button
          size="sm"
          variant="primary"
          class="text-nowrap"
          @click="editar(row.item)"
          ><i class="fa fa-edit"></i> Editar</b-button
        >
        <!-- <b-button size="sm" variant="danger" @click="excluir(row.item)"
          ><i class="fa fa-trash"></i> Excluir</b-button
        > -->
      </template>

      <template #cell(telefones)="row">
        <div
          v-for="tel in row.item.telefones"
          :key="`telefone_${row.item.telefones.indexOf(tel)}`"
          class="d-block text-truncate"
        >
          {{ tel.telefone }}
        </div>
      </template>

      <template #cell(endereco)="row">
        <div
          v-for="end in row.item.enderecos"
          :key="`endereco_${row.item.enderecos.indexOf(end)}`"
          class="d-block text-truncate"
        >
          {{ end.cidade }} - {{ end.uf }}
        </div>
      </template>
    </b-table>
    <b-pagination
      v-model="paginacao.pagina_atual"
      :total-rows="lista.length"
      :per-page="paginacao.registros_por_pagina"
    >
    </b-pagination>
    <b-modal
      id="modal-empresa"
      size="lg"
      title="Cadastrar/Editar Empresa"
      no-fade
      content-class="prodModal"
      hide-footer
    >
      <EmpresaForm :empresa_edit="empresa_edit" @salvou="carregar()" />
    </b-modal>
  </div>
</template>

<script>
import EmpresasLib from "@/libs/EmpresasLib";
import EmpresaForm from "./EmpresaForm.vue";
export default {
  components: {
    EmpresaForm,
  },
  props: {
    filtro: String,
  },
  data() {
    return {
      loading: false,
      lista: [],
      empresa_edit: {},
      campos: [
        { key: "cod_empresa", label: "Código", sortable: true },
        { key: "razao_social", label: "Razão Social", sortable: true },
        { key: "nome", label: "Nome", sortable: true },
        { key: "fantasia", label: "Fantasia", sortable: true },
        { key: "cnpj", label: "CNPJ", sortable: true },
        { key: "endereco", label: "Cidade", sortable: true },
        { key: "telefones", label: "Telefones", sortable: true },
        { key: "actions", label: "" },
      ],
      paginacao: {
        registros_por_pagina: 10,
        pagina_atual: 1,
      },
    };
  },
  mounted() {
    this.carregar();
  },
  methods: {
    async carregar() {
      this.loading = true;
      try {
        let result = await EmpresasLib.get();
        if (result.success) {
          this.lista = result.data;
        } else {
          console.warn(result.message);
          this.$swal.fire({
            title: "Erro ao carregar empresas!",
            icon: "false",
            toast: true,
            timer: 1500,
            position: "top-end",
            showConfirmButton: false,
          });
        }
      } catch (error) {
        console.warn(error);
        this.$swal.fire({
          title: "Erro ao carregar empresas!",
          icon: "false",
          toast: true,
          timer: 1500,
          position: "top-end",
          showConfirmButton: false,
        });
      } finally {
        this.loading = false;
      }
    },
    onRowSelected(items) {
      if (items) {
        this.editar(items[0]);
      }
      this.$refs.empresasTable.clearSelected();
    },
    limpa() {
      this.empresa_edit = {
        razao_social: "",
        fantasia: "",
        sigla: "",
        cnpj: "",
        enderecos: [],
        telefones: [],
        emails: "",
        logo: "",
        cod_empresa_pai: null,
        configuracoes: {
          frete_padrao: 0,
          taxa_servico: 0,
          senha_buscar: null,
          senha_balcao: null,
          senha_entrega: null,
          centro_custo_id: null,
          app_ativo: 0,
          app_aberto_de: null,
          app_aberto_ate: null,
        },
      };
    },
    async novo() {
      //   this.limpa();
      this.empresa_edit = {};
      this.$bvModal.show("modal-empresa");
    },

    // async excluir(empresa) {
    //   if (empresa) {
    //     this.$swal
    //       .fire({
    //         title: "Você tem creteza que deseja excluir esta empresa?",
    //         icon: "warning",
    //         showCancelButton: true,
    //         confirmButtonText: "Sim. Excluir!",
    //         cancelButtonText: "Não.",
    //       })
    //       .then(async (result) => {
    //         if (result.isConfirmed) {
    //           const res = true;
    //           if (res && res.success) {
    //             // await this.carregar();
    //             this.$swal.fire({
    //               title: "Empresa excluída com sucesso!",
    //               icon: "success",
    //               toast: true,
    //               timer: 1500,
    //               position: "top-end",
    //               showConfirmButton: false,
    //             });
    //           } else {
    //             this.$swal.fire({
    //               title: "Erro ao excluir empresa!",
    //               icon: "error",
    //               toast: true,
    //               timer: 1500,
    //               position: "top-end",
    //               showConfirmButton: false,
    //             });
    //           }
    //         }
    //       });
    //   }
    // },
    async editar(empresa) {
      // console.log("empresa recebida no editar", empresa)

      let configuracoes = Object.assign(
        {},
        empresa && empresa.configuracoes ? empresa.configuracoes : {}
      );
      configuracoes.taxa_servico = this.formatFloatToReal(
        configuracoes.taxa_servico
      );
      configuracoes.frete_padrao = this.formatFloatToReal(
        configuracoes.frete_padrao
      );
      configuracoes.frete_pg_padrao = this.formatFloatToReal(
        configuracoes.frete_pg_padrao
      );
      configuracoes.frete_online = this.formatFloatToReal(
        configuracoes.frete_online
      );
      configuracoes.frete_pg_online = this.formatFloatToReal(
        configuracoes.frete_pg_online
      );

      Object.assign(this.empresa_edit, empresa);
      this.empresa_edit.configuracoes = configuracoes;

      this.$forceUpdate();
      await this.$nextTick();
      // console.log(this.empresa_edit)
      this.$bvModal.show("modal-empresa");
    },
    endEdit() {
      this.$bvModal.hide("modal-empresa");
      this.limpa();
    },
  },
};
</script>

<style></style>
