<template>
  <div>
    <BarraCrud :botaoNovo="true" :onNovo="novo" :onFiltrar="filtrar" />
    <EmpresaTable ref="empresaTableComp" :filtro="filtro" />
  </div>
</template>

<script>
import EmpresaTable from "@/components/empresa/EmpresaTable.vue";
import BarraCrud from "@/components/common/BarraCrud";
export default {
  components: {
    BarraCrud,
    EmpresaTable,
  },
  data() {
    return {
      filtro: "",
    };
  },
  methods: {
    novo() {
      this.$refs.empresaTableComp.novo();
    },
    filtrar(filtro) {
      this.filtro = filtro;
    },
  },
};
</script>

<style></style>
