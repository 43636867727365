<template>
  <div>
    <div class="row">
      <div class="col-12">
        <div class="">
          <div class="">
            <table class="table table-striped table-hover table-sm">
              <thead>
                <tr>
                  <th>Contato</th>
                  <th>Telefone</th>
                  <th></th>
                </tr>
              </thead>

              <tbody>
                <tr
                  v-for="telefone in telefones"
                  :key="`telefone_${telefones.indexOf(telefone)}`"
                >
                  <td>{{ telefone.contato }}</td>
                  <td>{{ telefone.telefone }}</td>

                  <td>
                    <b-button
                      size="sm"
                      variant="primary"
                      class="mt-1 mb-1"
                      @click="editTelefone(telefone)"
                      ><i class="fa fa-edit"></i> Editar</b-button
                    >

                    <b-button
                      size="sm"
                      variant="danger"
                      class="mt-1 mb-1"
                      @click="deleteTelefone(telefones.indexOf(telefone))"
                      ><i class="fa fa-trash"></i> Excluir</b-button
                    >
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    telefones: Array,
  },
  methods: {
    editTelefone(telefone) {
      this.$emit('editar', telefone)
    },
    deleteTelefone(id) {
      this.$swal
        .fire({
          title: "Você tem creteza que deseja excluir este telefone?",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "Sim. Excluir!",
          cancelButtonText: "Não.",
        })
        .then(async (result) => {
          if (result.isConfirmed) {
            try {
              this.telefones.splice(id, 1);
              this.$swal.fire({
                title: "Telefone excluído com sucesso!",
                icon: "success",
                toast: true,
                timer: 1500,
                position: "top-end",
                showConfirmButton: false,
              });
            } catch (error) {
              this.$swal.fire({
                title: "Erro ao excluir telefone!",
                icon: "error",
                toast: true,
                timer: 1500,
                position: "top-end",
                showConfirmButton: false,
              });
              console.warn(error);
            }
          }
        });
    },
  },
};
</script>

<style>
</style>