<template>
  <div>
    <h4 v-if="title">Telefones</h4>
    <b-modal
      id="modal-telefone"
      header-bg-variant="transparent"
      bodyTextVariant=""
      fades
      body-bg-variant=""
      title=""
      size=""
      hide-footer
    >
      <template #modal-header>
        <h3><i class="fa fa-phone-alt"></i> Adicionar Telefone</h3>
      </template>
      <TelefonesForm
        ref="TelefonesForm"
        :telefoneEdit="telefone_edit"
        @salvou="salvar($event)"
      />
    </b-modal>

    <!-- <Collapse nomeComponente="Telefones" iconeClasse="fas fa-phone-square text-danger" > -->
    <TelefonesTable :telefones="telefones" @editar="editar($event)" />

    <div class="row">
      <div class="col-12">
        <b-button size="sm" variant="transparent" @click="novo">
          <i class="fa fa-plus"></i> Adicionar
        </b-button>
      </div>
    </div>
    <!-- </Collapse> -->
  </div>
</template>

<script>
import TelefonesForm from "./TelefonesForm.vue";
import TelefonesTable from "./TelefonesTable.vue";
// import Collapse from '../common/Collapse'

export default {
  props: {
    telefones: Array,
    title: {type:Boolean, default: false}
  },
  data() {
    return {
      visible: false,
      telefone_edit: {},
      edit: false,
    };
  },
  components: {
    TelefonesForm,
    TelefonesTable,
    // Collapse
  },
  methods: {
    async novo() {
      this.telefone_edit = {};
      this.$bvModal.show("modal-telefone");
    },
    salvar(telefone) {
      // console.log('telefone recebido', telefone)
      if (typeof telefone.index == "number") {
        
        this.$set(this.telefones, telefone.index, telefone)
        // console.log('telefones',this.telefones)
      } else {
        this.telefones.push(telefone);
      }
      
    },
    editar(telefone) {
      if (telefone) {
        const index = this.telefones.indexOf(telefone);
        Object.assign(this.telefone_edit, { ...telefone, index });

        this.$bvModal.show("modal-telefone");
      }
    },
  },
};
</script>

<style></style>
