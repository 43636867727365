<template>
  <div>
    <b-form @submit.stop.prevent="salvar()">
      <div class="row">
        <div class="col-12">
          <b-form-group>
            <label>Contato</label>
            <b-input
              type="text"
              placeholder="Contato"
              v-model="telefone.contato"
            />
            <b-form-invalid-feedback
              >Informe um nome de contato
            </b-form-invalid-feedback>
          </b-form-group>
        </div>
        <div class="col-12">
          <b-form-group
            ><label>Telefone</label>
            <b-input
              type="text"
              placeholder="Telefone"
              v-mask="['(##) ####-####', '(##) #####-####']"
              v-model="telefone.telefone"
            />
            <b-form-invalid-feedback
              >Informe um telefone válido
            </b-form-invalid-feedback></b-form-group
          >
        </div>
      </div>

      <div class="bottom-actions" style="position: unset;">
        <hr class="mb-2" />
        <b-btn variant="primary" type="submit" class="mr-3"
          ><i class="fas fa-save"></i> Salvar</b-btn
        >
        <b-btn variant="danger" @click="fechar()"
          ><i class="fas fa-ban"></i> Cancelar</b-btn
        >
      </div>
    </b-form>
  </div>
</template>

<script>
// import Validadores from "../../helpers/Validadores.js";
export default {
  props: {
    telefoneEdit: Object,
  },
  data() {
    return {
      hasErrors: [],
      telefone: {
        contato: "",
        telefone: "",
      },
      editando: false,
    };
  },
  methods: {
    salvar() {
      this.$emit("salvou", this.telefone);
      // console.log('telefone no form', this.telefone)
      this.fechar();
    },
    fechar() {
      this.$bvModal.hide("modal-telefone");
    },
    //  validateState(campo) {
    //     let hasError = this.hasErrors.find((x) => x.campo == campo);
    //     if (!hasError) return null;
    //     else return hasError.state;
    //   },
    //   addOrUpdateError(campo, state) {
    //     let hasError = this.hasErrors.find((x) => x.campo == campo);
    //     if (!hasError) {
    //       this.hasErrors.push({ campo, state });
    //     } else {
    //       hasError.state = state;
    //     }
    //   },
    //   clearErrors() {
    //     this.hasErrors.map((error) => {
    //       error.state = null;
    //     });
    //   },
    //   async validar() {
    //     this.clearErrors();
    //     this.addOrUpdateError(
    //       "telefone",
    //       Validadores.string.validaTelefone(this.telefoneEdit.telefone)
    //     );
    //     await this.$nextTick();
    //     return this.hasErrors.filter((x) => x.state == true).length >= 1;
    //   },
  },
  mounted() {
    if (typeof this.telefoneEdit.index == "number") {
      Object.assign(this.telefone, this.telefoneEdit);
      this.editando = true;
    }
  },
  //  watch:{
  //     telefoneEdit: {
  //       deep: true,
  //       handler(){
  //         this.validar();
  //       },

  //     },

  //  }
};
</script>

<style scoped></style>
