<template>
  <div>
    <b-table
    :items="lista"
    
    >
    <template #cell(tipo_entrega)="row">
      {{ row.item.tipo_entrega}}
    </template>
    <template #cell(desconta)="row">
      <b-checkbox switch v-model="row.item.desconta" :value="true" />
    </template>
    <template #cell(cod_custo)="row">
      <b-select :options="centroCustos" v-model="row.item.cod_custo" :reduce="(it)=>it.cod_custo" />
    </template>
  </b-table>
  </div>
</template>

<script>
import CentrosDeCustoLib from "../../libs/CentrosDeCustoLib";
const tipos_entrega = ["BALCÃO", "BUSCAR", "ENTREGA", "MESA"];
export default {
  components: {},
  props: {
    listaRec: Array,
    cod_empresa: Number,
  },
  async mounted() {
    this.centroCustos = (await CentrosDeCustoLib.get("", this.cod_empresa)).map(it => ({
      value: it.cod_custo,
      text: it.nome
    }));
    this.lista = this.listaRec;
    if (!this.lista || this.lista.length <= 0) {
      this.lista = tipos_entrega.map((tp) => ({
        tipo_entrega: tp,
        desconta: false,
        cod_custo: 0,
      }));
    }
  },
  data() {
    return {
      lista: [],
      centroCustos: [],
      obj: {
        id: 0,
        desconta: false,
        tipo_entrega: "",
        cod_centro_custo: "",
        centro_custo: "",
      },
    };
  },
  watch: {
    lista: {
      deep: true,
      handler() {
        this.$emit("change", this.lista);
      },
    },
  },
  computed: {},
  methods: {},
};
</script>

<style lang="scss" scoped></style>
