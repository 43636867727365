<template>
  <div>
    <div class="text-right m-2">
      <b-btn variant="outline" @click="novo"><b-icon-plus /> Adicionar</b-btn>
    </div>
    <b-table
      :items="lista"
      :fields="[
        { key: 'Tipo' },
        { key: 'Sistema' },
        { key: 'Ativo', label: 'Ativo', class: 'text-center' },
        { key: 'Padrao', label: 'Padrão', class: 'text-center' },
        { key: 'Opcoes', label: '', class: 'text-center' },
      ]"
    >
      <template #cell(Ativo)="row">
        <b-icon-check-circle v-if="row.item.Ativo === true" />
      </template>
      <template #cell(Padrao)="row">
        <b-icon-check-circle v-if="row.item.Padrao === true" />
      </template>
      <template #cell(Opcoes)="row">
        <div class="text-center">
          <b-btn
            variant="warning"
            class="mr-1"
            @click="editarIntegracao(row.item)"
            ><i class="fa fa-edit" />
          </b-btn>
          <b-btn variant="danger" @click="removerIntegracao(row.item)"
            ><i class="fa fa-trash" />
          </b-btn>
        </div>
      </template>
    </b-table>
    <b-modal
      id="modal-add-integracao"
      hide-footer
      title="Adicionar/Editar Integração"
    >
      <div class="container">
        <div class="row">
          <div class="col-12 col-sm-6 pb-4">
            <b-check switch v-model="obj.Padrao"
              >Tornar Padrão para o TIPO</b-check
            >
          </div>
          <div class="col-12 col-sm-6 pb-4">
            <b-check switch v-model="obj.Ativo">ATIVO?</b-check>
          </div>
          <div class="col-12 col-sm-6">
            <b>Tipo</b><br />
            <b-select :options="['Fiscal', 'Delivery']" v-model="obj.Tipo" />
          </div>
          <div class="col-12 col-sm-6">
            <b>Sistema</b><br />
            <b-select
              :options="['VHSYS', 'OnPedido', 'IFood']"
              v-model="obj.Sistema"
            />
          </div>

          <div class="col-12 col-sm-6 pt-3">
 
            <b>Usuario / ClientID / etc</b><br />
            <b-input
              v-model="obj.Login"
              :disabled="
                obj.Sistema === 'IFood' &&
                obj.Login &&
                obj.Login != '' &&
                obj.Adicionais &&
                obj.Adicionais.some((x) => x.chave == 'autorizado') &&
                obj.Adicionais.find((x) => x.chave == 'autorizado').valor ==
                  'true'?true:false
              "
            />
            <div
              v-if="
                obj.Sistema === 'IFood' &&
                obj.Login &&
                obj.Login != '' &&
                obj.Adicionais &&
                obj.Adicionais.find((x) => x.chave == 'autorizado') &&
                obj.Adicionais.find((x) => x.chave == 'autorizado').valor ==
                  'true'
              "
              class="text-success"
            >
              <b-icon-check-circle /> Acesso autorizado!
              <b-btn @click="obj.Login = ''" variant="danger"
                ><b-icon-x /> Cancelar Acesso</b-btn
              >
            </div>
            <b-btn
              variant="danger"
              @click="solicitarChaveIfood"
              v-if="
                obj.Sistema === 'IFood' &&
                (!obj.Login || obj.Login == '') ||
                (!obj.Adicionais ||
                  !obj.Adicionais.find((x) => x.chave == 'autorizado') ||
                  obj.Adicionais.find((x) => x.chave == 'autorizado').valor !=
                    'true')
              "
              :disabled="loadingIfood"
            >
              <b-spinner small v-if="loadingIfood" />
              <b-icon-key v-if="!loadingIfood" /> Solicitar Autorização
            </b-btn>
            <b-btn
              variant="warning"
              @click="autorizarChaveIfood"
              v-if="
                obj.Sistema === 'IFood' &&
                obj.Login &&
                obj.Login != '' &&
                (!obj.Adicionais ||
                  !obj.Adicionais.find((x) => x.chave == 'autorizado') ||
                  obj.Adicionais.find((x) => x.chave == 'autorizado').valor !=
                    'true')
              "
              :disabled="loadingIfood"
            >
              <b-spinner small v-if="loadingIfood" />
              <b-icon-key v-if="!loadingIfood" /> Confirmar Autorização
            </b-btn>
          </div>
          <div class="col-12 col-sm-6 pt-3" v-if="obj.Sistema !== 'IFood'">
            <b>Senha / ClientSecret</b><br />
            <b-input v-model="obj.Senha" />
          </div>
          <div class="col-12 pt-3" v-if="obj.Sistema !== 'IFood'">
            <b>Token</b><br />
            <b-input v-model="obj.Token" />
          </div>
          <div class="col-12 pt-3">
            <hr />
            <b>Adicionais</b>
            <b-table
              :items="obj.Adicionais"
              :fields="[
                { key: 'chave', class: 'text-center' },
                { key: 'valor', class: 'text-center' },
                { key: 'opc', label: '' },
              ]"
            >
              <template #cell(valor)="row">
                <div style="word-break: break-all">
                  <b-input placeholder="Valor" v-model="row.item.valor" />
                </div>
              </template>
              <template #cell(opc)="row">
                <b-btn variant="danger" @click="delAdd(row.item)"
                  ><b-icon-trash
                /></b-btn>
              </template>
            </b-table>
            <b-card>
              <div class="row">
                <div class="col-5">
                  <b-input placeholder="Chave" v-model="add.chave" />
                </div>
                <div class="col-5">
                  <b-input placeholder="Valor" v-model="add.valor" />
                </div>
                <div class="col-2">
                  <b-btn @click="addAdd"><b-icon-plus /></b-btn>
                </div>
              </div>
            </b-card>
          </div>
        </div>
      </div>
      <div class="text-right">
        <hr />
        <b-btn variant="success" class="mr-1" @click="salvar"
          ><i class="fa fa-save" /> Salvar</b-btn
        >
        <b-btn variant="secondary" @click="cancel"
          ><i class="fa fa-ban" /> Cancelar</b-btn
        >
      </div>
    </b-modal>
  </div>
</template>

<script>
import IFoodLib from "../../libs/IFoodLib";
export default {
  components: {},
  props: {
    lista: Array,
  },
  mounted() {},
  data() {
    return {
      obj: {
        id: 0,
        Tipo: "",
        Sistema: "",
        Login: "",
        Senha: "",
        Token: "",
        Adicionais: [],
        Padrao: false,
        Ativo: true,
      },
      add: {
        chave: "",
        valor: "",
      },
      loadingIfood: false,
    };
  },
  watch: {
    lista: {
      deep: true,
      handler() {
        this.$emit("change", this.lista);
      },
    },
  },
  computed: {},
  methods: {
    async autorizarChaveIfood() {
      let erros = [];
      if (!this.obj.Login || this.obj.Login == "") {
        erros.push("Chave de autorização não inserida ainda!");
      }
      if (
        !this.obj.Adicionais ||
        !this.obj.Adicionais.some((x) => x.chave == "authorizationCodeVerifier")
      ) {
        erros.push("Chave de autorização não inserida ainda!");
      }

      if (erros.length > 0) {
        this.$swal({
          title: "Atenção",
          html: erros.join("<hr/>"),
          icon: "error",
        });
      } else {
        this.loadingIfood = true;
        let resultToken = await IFoodLib.getConfirmacaoAcesso(
          this.obj.Login,
          this.obj.Adicionais.find(
            (x) => x.chave == "authorizationCodeVerifier"
          ).valor
        );
        if (resultToken && resultToken.success) {
          this.obj.Adicionais.push({
            chave: "autorizado",
            valor: "true",
          });
        }

        this.loadingIfood = false;
      }
    },
    removerIntegracao(int) {
      this.lista = this.lista.filter((x) => x != int);
    },
    async solicitarChaveIfood() {
      this.loadingIfood = true;
      let res = await IFoodLib.solicitarCodigoAcessoIFOOD();
      console.log("RESULT", res);
      if (res && res.success == true) {
        if (res.data.verificationUrlComplete) {
          this.obj.Adicionais = Object.keys(res.data).map((k) => ({
            chave: k,
            valor: res.data[k],
          }));
          this.obj.Adicionais.push({
            chave: "NomeLoja",
            valor: "",
          });
          this.obj.Adicionais.push({
            chave: "Automatico",
            valor: "true",
          });
          this.obj.Adicionais.push({
            chave: "Status",
            valor: "Aberta",
          });
          this.obj.Adicionais.push({
            chave: "IdLoja",
            valor: "",
          });
             this.obj.Adicionais.push({
            chave: "cod_caixa",
            valor: "1",
          });

          window.open(
            res.data.verificationUrlComplete,
            "ifood",
            "width=600, height=600"
          );
        }
      }
      this.loadingIfood = false;
    },
    delAdd(it) {
      this.obj.Adicionais = this.obj.Adicionais.filter((x) => x != it);
    },
    addAdd() {
      this.obj.Adicionais.push(this.add);
      this.add = {
        chave: "",
        valor: "",
      };
    },
    novo() {
      this.reset();
      this.$bvModal.show("modal-add-integracao");
    },
    reset() {
      this.obj = {
        id: 0,
        Tipo: "",
        Sistema: "",
        Login: "",
        Senha: "",
        Token: "",
        Adicionais: [],
      };
    },
    editarIntegracao(item) {
      this.obj = JSON.parse(JSON.stringify(item));
      this.$bvModal.show("modal-add-integracao");
    },
    cancel() {
      this.reset();
      this.$bvModal.hide("modal-add-integracao");
    },
    salvar() {
      let errs = [];
      if (this.obj.Tipo == "") {
        errs.push("Informe um Tipo de integração!");
      }
      if (this.obj.Sistema == "") {
        errs.push("Informe um Sistema para integrar");
      }

      if (errs.length > 0) {
        this.$swal({
          title: "Atenção!",
          html: "Os campos abaixo precisam de atenção:" + errs.join("<hr/>"),
        });
      } else {
        if (this.obj.id > 0) {
          let has = this.lista.find((x) => x.id === this.obj.id);
          if (has) {
            has = Object.assign(has, this.obj);
          }
        } else {
          let ult = this.lista.sort((a, b) => (a.id > b.id ? -1 : 1));

          if (ult.length > 0) {
            this.obj.id = ult[0].id + 1;
          } else {
            this.obj.id = 1;
          }
          this.lista.push(this.obj);
        }
        if (this.obj.Padrao === true) {
          this.lista
            .filter((x) => x.id != this.obj.id && x.Tipo == this.obj.Tipo)
            .forEach((it) => {
              it.Padrao = false;
            });
        }
        this.cancel();
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
