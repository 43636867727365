<template>
  <div>
    <b-avatar
      :src="formataUrl(logo)"
      class="avatar"
      size="190px"
      @click.native="selectFile()"
    />

    <input
      type="file"
      ref="fl"
      style="visibility: hidden; width: 0; height: 0px; margin: 0"
      @change="uploadFile($event)"
    />
  </div>
</template>
<style scoped>
.recordConfirmation {
  font-size: 30px;
}
</style>
<script>
import Vue from "vue";
import axios from "@/utils/axios.js";
export default {
  props: {
    logo: String,
  },
  data() {
    return {
      loading: false,
      uploaded: false,
      file: "",
      token: this.$store.state.auth.user.token,
      fileName: "",
    };
  },
  mounted() {
    this.loading = false;
    this.uploaded = false;
    this.file = "";
    this.fileName = "";

    // if (this.logo) {
    //   console.log(this.logo);
    // }
  },
  computed: {
    isImage() {
      return (
        this.file
          .toString()
          .toLowerCase()
          .indexOf(".jpg") >= 0 ||
        this.file.toString().indexOf(".png") >= 0 ||
        this.file.toString().indexOf(".bmp") >= 0 ||
        this.file.toString().indexOf(".gif") >= 0
      );
    },
    isPDF() {
      return (
        this.file
          .toString()
          .toLowerCase()
          .indexOf(".pdf") >= 0 ||
        this.file
          .toString()
          .toLowerCase()
          .indexOf(".txt") >= 0
      );
    },
  },
  methods: {
    async selectFile() {
      this.$refs.fl.value = "";
      this.$refs.fl.click();
    },
    formataUrl(url) {
      if (url) {
        if (url.indexOf("blob") >= 0) {
          return url;
        } else {
          return `${axios.defaults.baseURL}/download/${url}?token=${this.token}`;
        }
      }
    },
    async uploadFile() {
      await Vue.nextTick();
   
      // console.log(this.$refs.fl.files);
      //  console.log(this.$refs.fl.files[0].name);
      if (this.$refs.fl.files.length > 0) {
        this.fileName = this.$refs.fl.files[0].name;
        var form = new FormData();

        form.append("file", this.$refs.fl.files[0]);

        this.loading = true;
        await Vue.nextTick();
        axios
          .post("/uploadFile", form, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
          .then((data) => {
            // console.log(data);
            if (data && data.data && data.status == 200) {
              this.file = data.data;
              this.pronto = true;
              this.loading = false;
              this.uploaded = true;

              this.$emit("fileAddOrchanged", this.file);
            }
          });
      }
    },
  },
};
</script>

<style scoped>
.avatar {
  display: flex;
  cursor: pointer;
  justify-content: center;
}

.avatar:hover {
  transition: 0.5s step-start;
  box-shadow: 5px 10px rgba(0, 0, 0, 0.1);
  opacity: 0.8;
}
</style>
