<template>
  <div class="mt-3 mb-3">
    <div class="row mb-3">
      <div class="col-6">
        <b-input
          style="max-width: 250px"
          placeholder="Filtrar"
        ></b-input>
      </div>
      <div class="col-6 text-right">
        <b-btn
          variant="info"
          @click="openForm(null)"
          ><i class="fa fa-plus" /> Adicionar</b-btn
        >
      </div>
    </div>
    <hr />
    <b-table
      :items="lista"
      :fields="[
        { label: 'Codigo', key: 'id' },
        { label: 'Aplicativo', key: 'nome' },
        { label: 'Tipo', key: 'tipo' },
        { label: 'Ativo', key: 'status' },
        { label: '', key: 'opc' },
      ]"
    >
      <template #cell(status)="row">
        <div>
          <i
            class="fa fa-check-circle text-success"
            v-if="row.item.status == 1"
          />
          <i
            class="fa fa-times-circle text-danger"
            v-if="row.item.status != 1"
          />
        </div>
      </template>
      <template #cell(opc)="row">
        <i
          class="fa fa-edit cursor"
          @click="openForm(row.item)"
        ></i>
        <i
          class="fa fa-trash cursor ml-2"
          @click="delet(row.item)"
        ></i>
      </template>
    </b-table>
    <b-modal
      id="modal-edit-app"
      size="lg"
      no-fade
      content-class="prodModal"
      hide-footer
      title="Adicionar/Editar Aplicativo"
    >
      <div
        class="row"
        v-if="objEdit"
      >
        <div class="col-2">
          <label>Logotipo</label>
          <file-upload
            :value="objEdit.logo"
            v-model="objEdit.logo"
            :maxFiles="1"
            :enableTitle="false"
          ></file-upload>
        </div>
        <div class="col">
          <div>
            <label>Nome / Referencia</label>
            <b-input v-model="objEdit.nome"></b-input>
          </div>
          <div class="d-flex">
            <div>
              <label>Tipo</label>
              <div>
                <b-select
                  :options="['Cardápio Digital', 'Mesa/Salão', 'Delivery']"
                  v-model="objEdit.tipo"
                  style="max-width: 200px"
                />
              </div>
            </div>
            <div class="ml-2">
              <label>URL</label>
              <div>
                <b-input
                  v-model="objEdit.url"
                  style="width: 500px"
                />
              </div>
            </div>
          </div>
        </div>

        <div class="col-2">
          <label>Status</label>
          <b-checkbox
            switch
            v-model="objEdit.status"
            value="1"
            >Ativo</b-checkbox
          >
        </div>
        <div class="col-12">
          <b-tabs>
            <b-tab>
              <template v-slot:title>
                <i class="fa fa-calendar mr-1" /> Horários
              </template>
              <b-card class="m-4">
                <h4><b-icon-phone /> Gerenciamento de Aplicativo</h4>
                <div class="row">
           
                  <div class="col-12"></div>
                  <div class="col-6 mt-3">
                    <label><b-icon-clock /> Horário de Funcionamento</label>
                    <div class="row">
                      <div class="col-6">
                        <b-input type="time" />
                      </div>
                      <div class="col-6">
                        <b-input type="time" />
                      </div>
                    </div>
                  </div>
                </div>
              </b-card>
            </b-tab>
            <b-tab>
              <template v-slot:title>
                <i class="fa fa-layer-group mr-1"></i>Categorias
              </template>

              <b-table
                :items="categorias"
                :fields="[
                  { label: 'Nome', key: 'nome' },
                  { label: 'Status', key: 'status' },
                  { label: 'Categorias', key: 'categorias' },
                  { label: 'Icone', key: 'icone', class: 'colRedu' },
                  { label: 'Imagem', key: 'imagem', class: 'colRedu' },
                  { label: '', key: 'opc' },
                ]"
              >
                <template #cell(icone)="row">
                  <div style="max-width: 200px">
                    <file-upload
                      :value="row.item.icone"
                      v-model="row.item.icone"
                      :maxFiles="1"
                      :enableTitle="false"
                      :key="'fileupd' + Math.random()"
                    />
                  </div>
                </template>
                <template #cell(imagem)="row">
                  <div style="max-width: 200px">
                    <file-upload
                      :value="row.item.imagem"
                      v-model="row.item.imagem"
                      :maxFiles="1"
                      :enableTitle="false"
                      :key="'fileupd' + Math.random()"
                    />
                  </div>
                </template>
                <template #cell(nome)="row">
                  <b-input
                    v-model="row.item.nome"
                    placeholder="Nome"
                  />
                </template>
                <template #cell(categorias)="row">
                  <div style="min-width: 300px">
                    <v-select
                      v-model="row.item.categorias"
                      multiple
                      label="Grupo"
                      :options="grupos"
                      :reduce="(it) => it.cod_grupo"
                    />
                  </div>
                </template>
                <template #cell(status)="row">
                  <b-checkbox
                    switch
                    value="1"
                    v-model="row.item.status"
                    >Ativo</b-checkbox
                  >
                </template>
              </b-table>
              <div class="text-right">
                <b-btn
                  variant="info"
                  @click="addCategoria"
                  ><i class="fa fa-plus"></i> Add. Categoria</b-btn
                >
              </div>
            </b-tab>
            <b-tab>
              <template v-slot:title>
                <i class="fa fa-images"></i> Banners
              </template>

              <b-table
                :items="banners"
                :fields="[
                  { label: 'Nome', key: 'nome' },
                  { label: 'Status', key: 'status' },
                  { label: 'Imagem', key: 'imagem', class: 'colRedu' },
                  { label: '', key: 'opc' },
                ]"
              >
                <template #cell(imagem)="row">
                  <div style="max-width: 200px">
                    <file-upload
                      :value="row.item.imagem"
                      v-model="row.item.imagem"
                      :maxFiles="1"
                      :enableTitle="false"
                      :key="'fileupd' + Math.random()"
                    />
                  </div>
                </template>
                <template #cell(nome)="row">
                  <b-input
                    v-model="row.item.nome"
                    placeholder="Nome"
                  />
                </template>

                <template #cell(status)="row">
                  <b-checkbox
                    switch
                    value="1"
                    v-model="row.item.status"
                    >Ativo</b-checkbox
                  >
                </template>
              </b-table>
              <div class="text-right">
                <b-btn
                  variant="info"
                  @click="addBanner"
                  ><i class="fa fa-plus"></i> Add. Banner</b-btn
                >
              </div>
            </b-tab>
            <b-tab>
              <template v-slot:title>
                <i class="fa fa-star mr-1"></i>Programas de Fidelização
              </template>

              <div class="p-5">
                <div class="row">
                  <div
                    class="col-12 col-md-4"
                    v-for="fidel in fidelizacoes"
                    :key="`fidel${fidel.tipo}`"
                  >
                    <b-card style="min-height: 280px">
                      <b-card-title>
                        <div class="d-flex justify-content-between">
                          <h5>
                            <i class="fa fa-star mr-2"></i>{{ fidel.tipo }}
                          </h5>
                        </div>
                      </b-card-title>
                      <b-card-text>
                        <small>{{ fidel.descricao }}</small>
                      </b-card-text>
                      <b-card-footer class="d-flex justify-content-between">
                        <b-checkbox
                          v-model="fidel.ativo"
                          switch
                          >Ativo</b-checkbox
                        >
                        <b-btn @click="editFidel(fidel)">Configurar</b-btn>
                      </b-card-footer>
                    </b-card>
                  </div>
                </div>
              </div>
            </b-tab>
          </b-tabs>
        </div>
      </div>
      <hr />
      <div class="text-right">
        <b-btn
          class="mr-1"
          variant="success"
          @click="salvar"
          ><i class="fa fa-save" /> Salvar</b-btn
        >
        <b-btn
          variant="secondary"
          @click="cancelar"
          ><i class="fa fa-ban" /> Cancelar</b-btn
        >
      </div>
    </b-modal>
    <b-modal
      id="modal-edit-fidel"
      hide-footer
      :title="objFidel.tipo"
      v-if="objFidel"
      size="lg"
    >
      <h4>Prêmios</h4>
      <hr />
      <div class="row">
        <div
          class="col-12"
          v-if="objFidel && objFidel.obj && objFidel.obj.premios"
        >
          <aplicativo-fidelizacao-premios
            v-if="objFidel.obj"
            :objEdit="objFidel.obj"
          ></aplicativo-fidelizacao-premios>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import ProdutoGrupoLib from "../../libs/ProdutoGrupoLib";
import FileUpload from "../common/FileUpload.vue";
import AplicativoFidelizacaoPremios from "./AplicativoFidelizacaoPremios.vue";
import { fidelizacoes as fidels } from "./tiposFidelizacao";

const refObj = {
  nome: "",
  categorias: [],
  status: 0,
  logo: [],
  banners: [],
  fidelizacoes: [],
};
export default {
  components: { FileUpload, AplicativoFidelizacaoPremios },
  props: {
    dados: Array,
  },
  mounted() {
    this.carregarGrupos();
    this.lista = JSON.parse(JSON.stringify(this.dados)) || [];
  },
  data() {
    return {
      objEdit: null,
      editing: false,
      grupos: [],
      lista: [],
      categorias: [],
      banners: [],
      fidelizacoes: Object.assign([], [...fidels]),
      objFidel: null,
    };
  },
  watch: {
    lista: {
      deep: true,
      handler() {
        this.$emit("change", this.lista);
      },
    },
    categorias: {
      deep: true,
      handler() {
        this.$forceUpdate();
      },
    },
  },
  computed: {},
  methods: {
    editFidel(fidel) {
      this.objFidel = fidel;
      this.$bvModal.show("modal-edit-fidel");
    },
    salvar() {
      let err = [];
      if (!this.objEdit.nome) {
        err.push("Informe um nome!");
      }
      if (!this.objEdit.tipo) {
        err.push("Informe um tipo");
      }
      if (!this.categorias || this.categorias.length <= 0) {
        err.push("Adicione uma categoria!");
      }

      if (err.length > 0) {
        this.$swal({
          title: "Atenção!",
          html: "Alguns campos precisam de atenção: <hr/>" + err.join("<hr/>"),
          icon: "error",
        });
      } else {
        this.objEdit.categorias = JSON.parse(JSON.stringify(this.categorias));
        this.objEdit.banners = this.banners
          ? JSON.parse(JSON.stringify(this.banners))
          : [];
        this.objEdit.fidelizacoes = this.fidelizacoes
          ? JSON.parse(JSON.stringify(this.fidelizacoes))
          : [];
        if (!this.editing) {
          this.objEdit.id =
            this.lista && this.lista.length > 0
              ? this.lista.sort((a, b) => b.id - a.id)[0].id + 1
              : 1;
          this.lista.push(this.objEdit);
        } else {
          let hasidx = this.lista.findIndex((x) => x.id == this.objEdit.id);
          this.lista[hasidx] = JSON.parse(JSON.stringify(this.objEdit));
        }
        this.$bvModal.hide("modal-edit-app");
        this.$forceUpdate();
        this.lista = this.lista.filter((x) => x == x);
      }
      console.log("this.objEdit", this.objEdit);
      setTimeout(() => {
        this.$set(this.lista, "lista");
        this.lista = this.lista.filter((x) => x == x);

        this.$nextTick();
        this.$forceUpdate();
      }, 200);
    },
    cancelar() {
      this.$bvModal.hide("modal-edit-app");
    },
    async carregarGrupos() {
      this.grupos = await ProdutoGrupoLib.get();
      console.log("grupos", this.grupos);
    },
    delet(obj) {
      this.$swal({
        title: "Tem certeza que deseja excluir esta configuração?",
        icon: "question",
        showConfirmButton: true,
        showCancelButton: true,
      }).then((x) => {
        if (x.isConfirmed) {
          this.lista = this.lista.filter((x) => x != obj);
        }
      });
    },
    openForm(obj) {
      console.log("this.objEdit", obj);

      if (!obj) {
        this.editing = false;

        obj = Object.assign({}, { ...refObj });
        this.categorias = [];
      } else {
        this.categorias = JSON.parse(JSON.stringify(obj.categorias));
        this.banners = obj.banners
          ? JSON.parse(JSON.stringify(obj.banners))
          : [];
        this.fidelizacoes = obj.fidelizacoes
          ? JSON.parse(JSON.stringify(obj.fidelizacoes))
          : Object.assign([], [...fidels]);
        this.editing = true;
      }
      this.objEdit = JSON.parse(JSON.stringify(obj));
      this.$forceUpdate();

      this.$bvModal.show("modal-edit-app");
    },
    addBanner() {
      if (!this.objEdit) {
        console.log("objedit error", this.objEdit);
        this.objEdit = Object.assign({}, { ...refObj });
      }
      if (!this.objEdit.banners) {
        console.log("objedit error cat", this.objEdit);

        this.objEdit.banners = [];
      }
      if (!this.banners) {
        this.banners = [];
      }
      this.banners.push({
        imagem: [],
        nome: "",
        status: 0,
      });

      this.$forceUpdate();
    },
    addCategoria() {
      if (!this.objEdit) {
        console.log("objedit error", this.objEdit);
        this.objEdit = Object.assing({}, { ...refObj });
      }
      if (!this.objEdit.categorias) {
        console.log("objedit error cat", this.objEdit);

        this.objEdit.categorias = [];
      }
      if (!this.categorias) {
        this.categorias = [];
      }
      this.categorias.push({
        icone: [],
        imagem: [],
        nome: "",
        status: 0,
        categorias: [],
      });
      console.log("objedit added", this.objEdit, this.categorias);

      this.$forceUpdate();
    },
  },
};
</script>

<style lang="scss" scoped>
.colRedu {
  max-width: 200px !important;
}
</style>
